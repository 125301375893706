@transitionDuration: 300ms;

.box {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0 6px;
    transition: all @transitionDuration;
    height: 100%;
    color: #333333;
    font-size: 14px;

    // border-left: 1px solid #ECECEC;
    span:first-child {
        width: 25px;
        height: 19px;
        margin-right: 5px;
    }

    &:hover {
        color: #1890ff;
        // background: #3491FB;
    }


    .userinfo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        p {
            padding: 0;
            margin: 0;
        }

        p:first-child {
            width: 30px;
            height: 30px;
            background: orange;
            border-radius: 50%;
        }
    }

}

.contact {
    span:first-child {
        background-image: url(../../pages/img/contact2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

    }

    &:hover span:first-child {
        background-image: url(../../pages/img/contact1.png);
    }
}

.manual {
    span:first-child {
        background-image: url(../../pages/img/manual2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

    }

    &:hover span:first-child {
        background-image: url(../../pages/img/manual1.png);
    }
}

.message {
    span:first-child {
        background-image: url(../../pages/img/message2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &:hover span:first-child {
        background-image: url(../../pages/img/message1.png);
    }
}

.inform {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0 6px;
    transition: all @transitionDuration;
    height: 100%;
    color: #333333;
    font-size: 14px;
    // border-left: 1px solid #ECECEC;
    // border-right: 1px solid #ECECEC;
    position: relative;



    &:hover {
        color: #1890ff;

        // background: #3491FB;
        .informMessages {
            height: 391px;
            color: #000;
        }
    }

    .informImg {
        width: 25px;
        height: 19px;
        margin-right: 5px;
        background-image: url(../../pages/img/inform2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &:hover .informImg {
        background-image: url(../../pages/img/inform1.png);
    }

    img {
        width: 17px;
        height: 19px;
        margin-right: 5px;
    }

    .inform-badge {
        display: inline-block;
        position: absolute;
        top: 12px;
        left: 12px;
        width: 20px;
        height: 20px;
        background: #FF0201;
        border-radius: 50%;
        border: 1px solid #fff;
        color: #fff;
        font-size: 8px;
        text-align: center;
        vertical-align: middle;
    }

    .informMessages {
        width: 462px;
        height: 0px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(236, 236, 236, 1);
        box-shadow: 0px 2px 9px 0px rgba(40, 40, 40, 0.1);
        position: absolute;
        right: 0;
        top: 64px;
        transition: all 0.3s;
        overflow: hidden;

        .informTitle {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #C2DEFE;

            div {
                padding: 16px;
            }

            img {
                width: 26px;
                height: 18px;
            }
        }

        .test {
            height: 270px;
            margin: 20px 12px 10px 12px;
            overflow: auto;
            box-Sizing: border-box;

            .test-box {
                display: flex;
                justify-content: space-between;
                padding: 12px 12px;

                .test-box-content {
                    width: 71%;

                    div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .test-box-time {
                    width: 27%;
                    font-size: 12px;
                    margin-left: 10PX;
                    color: #989AA0;
                }
            }

            .test-box:not(:first-child) {
                border-top: 1px solid #ECECEC;
            }

        }
    }
}





.test::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.test::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #C2DEFE;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
}

.test::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 10px;
}

.userMessage-title {
    border-bottom: 1px solid #C2DEFE;

    div {
        border-bottom: 1px solid #3491FB;
        padding: 16px 0 12px 16px;
        width: 95px;
    }
}

.userMessage-content {
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    margin: auto;
    margin-top: 35px;
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);