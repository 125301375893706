/*
全局样式，不要轻易编写全局样式
*/

html,
body,
#root {
    height: 100%;
    width: 99.5%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nprogress .bar {
    z-index: 9999;
}

.assetsmanagement-wai {
    width: 100%;
}

.assetsmanagement-wai .page-content-root-2n7ZF {
    width: 100%;
}

.assetsmanagement-wai .page-content-XxyQi {
    background: #f0f2f5 !important;
    padding: 16px 0px !important;
    width: 100%;

}

.assetsmanagement-wai .ant-tabs-nav-scroll {
    background: #fff;
}

.assetsmanagement-wai .ant-tabs-nav-scroll {
    width: 99%;
    margin-left: 16px !important;
}

.assetsmanagement-warp .disk-right-2mCnT {
    min-height: 775px;
}

/* .flowDetails .ant-form-horizontal{
    position: relative;
    left: 590px;
} */
.orderwarp .sx-query-bar {
    display: flex;
    justify-content: flex-end;
    z-index: 30;
    padding: 23px 40px 0 0;
    border: none;
    width: 65%;
    margin-left: 500px;
    background-color: #fff;
}

.orderwarp .query-item-button-container {
    justify-content: flex-end;
    flex: 0 1 auto;
    margin-bottom: 10px;
}

.base-frame-Hfora {
    width: 100%;
}

.management-oederWarp .ant-modal-header {}

.assetchongzhi .ant-modal-header {
    background: rgba(214, 233, 254, 1);
    color: rgba(52, 145, 251, 1);
}

.rechargeRest .ant-input-number {
    width: 140px;
    height: 48px;
    position: relative;
    margin-right: 6px;
}

.rechargeRest .ant-input-number-input {
    height: 48px;
}

.buyflow-rest input {
    height: 45px;
}

.playLogs-header .ant-select-selection-selected-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
}

.playLogs-header .query-item-element-container {
    justify-content: flex-end;
}

.playLogs-header .query-item-button-container {
    flex: none;
}

/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/

/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/

/* 只在打印时显示 */
.just-print {
    display: none !important;
}

@media print {
    body {
        padding: 0 !important;
        background: none;
    }

    /* 打印时不显示 */
    .no-print {
        display: none !important;
    }

    .just-print {
        display: block !important;
    }

    .ant-message {
        display: none !important;
    }

    .ant-modal-mask {
        display: none !important;
    }
}

.upSuCai .ant-modal-close {
    border-radius: 50%;
    background: #fff;
}

.ant-upload-list-item-actions .anticon.anticon-download {
    display: none;
}

.ant-calendar-today .ant-calendar-date {
    background: transparent;
}

/*树形控件*/
.ant-tree li .ant-tree-node-content-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
}

/* 表格 */
.ant-table-thead .ant-table-align-center {
    color: #258EFB;
}

.deviceSetTable .ant-table-align-center,
.publishing-table .ant-table-align-center {
    color: rgba(0, 0, 0, 0.85);
}

/* 部门机构 */
.ant-tree li span.ant-tree-iconEle.ant-tree-icon__customize {
    width: 6px;
}

/* 设备节目详情 */
.inputbg-disabled .ant-input.ant-input-disabled {
    background: #fff;
}

/* 设备编辑 */
.deviceEdit .ant-select-selection-selected-value,
.deviceEdit .ant-select-selection__placeholder {
    float: right;
}

.deviceEdit .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 86px;
}

.deviceEdit .ant-select-selection__placeholder {
    text-align: right
}

.quickPublish .ant-select-selection-selected-value {
    float: right;
}

.quickPublish .ant-select-selection--single {
    background: #F8F8F8;
}

input:-internal-autofill-selected {
    background-color: #fff !important;
    box-shadow: inset 0 0 0 1000px #fff !important;
}

/* 用户手册 */
.manual .ant-tabs-tab {
    text-align: center !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

/* 通知 */
.informClass .ant-badge-multiple-words {
    padding: 0;
}

.informClass .ant-badge-count {
    min-width: 16px;
    height: 16px;
    color: #fff;
    font-size: 7px;
    line-height: 16px;
}

.input-suffix .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 40px !important;
}

.queryItemWrap .query-item-element-container {
    flex-wrap: wrap;
    justify-content: flex-end;
}

#telLoginDivDisplay .ant-input-affix-wrapper .ant-input {
    width: 305px !important;
    height: 38px !important;
    border-radius: 0;
    padding: 0;
    padding-left: 50px;
    border: none;

}

#userNameLoginDiv .ant-input-affix-wrapper .ant-input {
    width: 305px !important;
    height: 40px !important;
    border-radius: 0;
    padding: 0;
    padding-left: 50px;
    border: none;
    border: 1px solid #c1c1c1;

}

.login-bg .ant-row {
    width: 307px;
    margin: 0 auto;
    height: 70px;
}

.login-bg .ant-input-prefix .img {
    width: 38px;
    height: 38px;
    position: absolute;
    left: -11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #c1c1c1;
    background-color: #F2F4F8;
}

.login-bg .ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
    /* border: 1px solid #C1C1C1; */
}

.ant-input-affix-wrapper .ant-input:focus {
    box-shadow: none;
}

.login-bg .ant-col.ant-col-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    /* border: 1px solid #c1c1c1; */
    border-left: 1px solid #c1c1c1;
    margin-left: -5px !important;
    /* background-color: #C1C1C1; */
}

.login-bg .code {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    /* border: 1px solid #c1c1c1; */
    border-left: 1px solid #c1c1c1;
    margin-left: -5px !important;
    /* background-color: #C1C1C1; */
}

.login-bg .submit-btn-3Yto5 {
    width: 304px;
    display: block;
    margin: 10px auto 0;
    height: 34px;
}

.ant-modal .ant-input {
    /* height: 38px; */
    border-radius: none;
}

.ant-form-item-children .ant-btn-primary {
    width: 86px;
    height: 38px;
    border-radius: 0;
    background: #F2F4F8;
    font-size: 14px;
    color: #999999;
    border: none;
    border-left: 1px solid #c1c1c1;
}

.ant-form-item-children .ant-input-search .ant-btn-primary {
    width: auto;
    height: 32px;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

#telLoginDivDisplay .ant-form-item-control-wrapper {
    border: 1px solid #c1c1c1;
    height: 41px;
}

#userNameLoginDiv .ant-form-item-control-wrapper input {
    border: 1px solid #c1c1c1;
}

.forgetPwd .img {
    width: 38px;
    height: 38px;
    position: absolute;
    left: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #c1c1c1;
    background-color: #F2F4F8;
}

.forgetPwd .ant-input {
    border-radius: 0;
    padding-left: 50px !important;
    border: none;
}

.forgetPwd {
    border: 1px solid #c1c1c1;
    margin: 30px auto 0;
    display: block;
}

.forgetPwd .ant-btn-primary {
    background-color: #F2F4F8;
    color: #999;
    border-radius: 0;
    border: none;
    border-left: 1px solid #c1c1c1;
}

.forgetPwd .picCode {
    border-left: 1px solid #c1c1c1;
}

.ant-modal-title {
    text-align: center;
}

.forgetPwd .forgetPwdBtn {
    background-color: #396AF9;
    color: #fff;
    border: none;
}

.forgetPwdBtn span {
    font-size: 20px;
}


/* .ant-modal-wrap{
    z-index: 1060 !important;
} */