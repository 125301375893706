.user-menu {
    display: inline-block;
    border: 1px solid cyan;
    
}

.menu {
    :global(.anticon) {
        margin-right: 8px;
    }

    :global(.ant-dropdown-menu-item) {
        width: 160px;
    }
}

.account {
    border:'1px solid blue';
    .avatar {
        margin-right: 8px;
        background: rgba(255, 255, 255, .85);
        vertical-align: middle;
    }

    i {
        margin-left: 4px;
    }
}

.hedaer-user-menu{
    
}

.hedaer-user-menu>p{
    cursor: pointer;
    margin-bottom: 0px !important;
    height: 30px;
    line-height: 30px;
}

.hedaer-user-menu>p:hover{
    color: skyblue;
}


.ant-popover-inner-content{
    padding:0px 10px !important;
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);