// .logo {
//     border:'1px solid red';
//     width: 100%;
//     height: 100%;
//     background: url(logo12.png) no-repeat center;
//     // background-size: 90%;
// }
.logo {
    display: flex;
    // align-items: center;
    // padding-left: 24px;
    width: 256px;
    height: 64px;
    padding: 10px;
    text-align: center;
    img {
        // height: 32px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    h1 {
        padding: 0;
        font-size: 20px;
        margin: 0 0 0 12px;
        font-weight: 600;
        white-space: nowrap;
        transition: transform 300ms;
        transform-origin: left;
        transform: scale(1);
    }

    :global(.title-hide) {
        transform: scale(0);
    }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);